import { graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { keyframes } from '@emotion/react';
import ReactPlayer from 'react-player';
import AspectRatio from '../aspect-ratio';

interface DatoAssetProps {
    asset?: Queries.DatoCmsInternalAssetFragment | Queries.DatoCmsExternalAssetFragment
    style?: React.CSSProperties
    className?: string
    objectFit?: React.CSSProperties['objectFit'];
    objectPosition?: React.CSSProperties['objectPosition'];
    autoPlay?: boolean;
    muted?: boolean;
    isStatic?: boolean
    onPlayStateChange?: (state: 'play' | 'pause') => void
    noAnimate?: boolean
    cannotOverrideInline?: boolean
    isBlog?: boolean
}

const DatoAsset = ({
    cannotOverrideInline, asset, style, className, objectFit = 'cover', noAnimate = false, objectPosition: overrideObjPosition, autoPlay = false, muted, isStatic, onPlayStateChange, isBlog, fromModal, onExitFullscreen,
}: DatoAssetProps): JSX.Element => {
    // Is an internal asset

    const objectPosition = overrideObjPosition || asset?.objectPosition;

    const fadeAnim = keyframes({
        '100%': {
            opacity: 1,
        },
    });

    const reactPlayerRef = React.useRef();

    const fullScreenEv = () => {
        if (!document.fullscreenElement) {
            if (onExitFullscreen) {
                onExitFullscreen();
            }
        }
    };

    React.useEffect(() => {
        if (reactPlayerRef?.current && fromModal) {
            window.addEventListener('fullscreenchange', fullScreenEv);
        }

        return () => {
            window.removeEventListener('fullscreenchange', fullScreenEv);
        };
    }, [fromModal]);

    if (asset && 'isImage' in asset) {
        const generatedAltData = asset?.smartTags && asset.smartTags.length > 0 ? `Image of ${asset.smartTags[0]}` : '';

        if (asset.format === 'svg') {
            return (
                <img
                    style={style}
                    alt={asset.alt || generatedAltData}
                    src={asset.url || undefined}
                    className={className}
                    css={{
                        objectFit,
                        objectPosition,
                    }}
                />
            );
        }

        if (asset.isImage && asset.gatsbyImageData) {
            return (
                <GatsbyImage
                    style={{
                        ...(style || {}),
                        objectFit,
                        objectPosition,
                    }}
                    className={className}
                    alt={asset.alt || generatedAltData}
                    image={asset.gatsbyImageData as unknown as IGatsbyImageData}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    css={noAnimate ? {} : {
                        opacity: 0,
                        animation: `${fadeAnim} 1s forwards`,
                        animationDelay: '800ms',
                    }}
                />
            );
        }

        if (!asset?.isImage && asset.video?.thumbnailUrl && isStatic) {
            return (
                <img
                    alt=""
                    src={asset.video.thumbnailUrl || ''}
                    style={style}
                    className={className}
                    css={{
                        objectFit,
                        objectPosition,
                    }}
                />
            );
        }

        if (!asset.isImage && asset.video?.thumbnailUrl && asset.video?.streamingUrl && !muted) {
            return (
                <div
                    className={className}
                    style={style}
                    css={noAnimate ? {} : {
                        opacity: 0,
                        animation: `${fadeAnim} 0.8s forwards`,
                    }}
                >
                    {(isBlog || fromModal) ? (
                        <AspectRatio breakpoints={{
                            dt: { width: 16, height: 9 },
                        }}
                        >
                            <ReactPlayer
                                ref={reactPlayerRef}
                                url={asset.video.streamingUrl}
                                playing={autoPlay}
                                playsinline={muted}
                                loop={autoPlay && muted && !cannotOverrideInline}
                                muted={muted}
                                config={{
                                    attributes: {
                                        poster: asset?.thumbnailUrl,
                                    },
                                }}
                                css={{
                                    video: {
                                        objectFit,
                                        objectPosition,
                                    },
                                }}
                                width="100%"
                                height="100%"
                                controls={!muted}
                                onReady={(e) => {
                                    if (fromModal) {
                                        if (window.innerWidth <= 767) {
                                            try {
                                                e.getInternalPlayer().requestFullscreen();
                                            } catch (e) {
                                            }
                                        }
                                        try {
                                            e.getInternalPlayer().play();
                                        } catch (e) {
                                        }
                                    }
                                }}
                                onPlay={() => {
                                    if (onPlayStateChange) {
                                        onPlayStateChange('play');
                                    }
                                }}
                                onPause={() => {
                                    if (onPlayStateChange) {
                                        onPlayStateChange('pause');
                                    }
                                }}
                                onEnded={() => {
                                    if (onPlayStateChange) {
                                        onPlayStateChange('pause');
                                    }
                                }}
                            />
                        </AspectRatio>
                    ) : (
                        <ReactPlayer
                            ref={reactPlayerRef}
                            url={asset.video.streamingUrl}
                            config={{
                                attributes: {
                                    poster: asset?.thumbnailUrl,
                                },
                            }}
                            playing={autoPlay}
                            playsinline={muted}
                            loop={autoPlay && muted && !cannotOverrideInline}
                            muted={muted}
                            css={{
                                video: {
                                    objectFit,
                                    objectPosition,
                                },
                            }}
                            width="100%"
                            height="100%"
                            controls={!muted}
                            onReady={(e) => {
                                if (fromModal) {
                                    if (window.innerWidth <= 767) {
                                        try {
                                            e.getInternalPlayer().requestFullscreen();
                                        } catch (e) {
                                        }
                                    }
                                    try {
                                        e.getInternalPlayer().play();
                                    } catch (e) {
                                    }
                                }
                            }}
                            onPlay={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('play');
                                }
                            }}
                            onPause={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('pause');
                                }
                            }}
                            onEnded={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('pause');
                                }
                            }}
                        />
                    )}

                </div>
            );
        }

        if (!asset.isImage && asset.video?.thumbnailUrl && asset.video?.streamingUrl) {
            return (
                <div
                    css={noAnimate ? {
                        width: '100%',
                        height: '100%',
                    } : {
                        opacity: 0,
                        animation: `${fadeAnim} 1s forwards`,
                        animationDelay: '800ms',
                        width: '100%',
                        height: '100%',
                    }}
                    style={style}
                    className={className}
                >
                    <ReactPlayer
                        ref={reactPlayerRef}
                        url={asset.video.streamingUrl}
                        config={{
                            attributes: {
                                poster: asset?.thumbnailUrl,
                            },
                        }}
                        playing={autoPlay}
                        playsinline={muted}
                        loop={autoPlay && muted && !cannotOverrideInline}
                        muted={muted}
                        css={{
                            video: {
                                objectFit,
                                objectPosition,
                            },
                        }}
                        width="100%"
                        height="100%"
                        onPlay={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('play');
                            }
                        }}
                        onPause={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('pause');
                            }
                        }}
                        onEnded={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('pause');
                            }
                        }}
                    />
                </div>
            );
        }
    }

    // Is an external asset
    if (asset && 'provider' in asset) {
        return (
            <div
                className={className}
                style={style}
                css={noAnimate ? {} : {
                    opacity: 0,
                    animation: `${fadeAnim} 0.8s forwards`,
                }}
            >
                {(isBlog || fromModal) ? (
                    <AspectRatio breakpoints={{
                        dt: { width: 16, height: 9 },
                    }}
                    >
                        <ReactPlayer
                            ref={reactPlayerRef}
                            url={asset.url}
                            config={{
                                attributes: {
                                    poster: asset?.thumbnailUrl,
                                },
                            }}
                            playing={autoPlay}
                            playsinline={muted}
                            loop={autoPlay && muted && !cannotOverrideInline}
                            muted={muted}
                            css={{
                                video: {
                                    objectFit,
                                    objectPosition,
                                },
                            }}
                            width="100%"
                            height="100%"
                            onPlay={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('play');
                                }
                            }}
                            onPause={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('pause');
                                }
                            }}
                            onEnded={() => {
                                if (onPlayStateChange) {
                                    onPlayStateChange('pause');
                                }
                            }}
                        />
                    </AspectRatio>
                ) : (
                    <ReactPlayer
                        ref={reactPlayerRef}
                        url={asset.url}
                        config={{
                            attributes: {
                                poster: asset?.thumbnailUrl,
                            },
                        }}
                        playing={autoPlay}
                        playsinline={muted}
                        loop={autoPlay && muted && !cannotOverrideInline}
                        muted={muted}
                        css={{
                            video: {
                                objectFit,
                                objectPosition,
                            },
                        }}
                        width="100%"
                        height="100%"
                        onPlay={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('play');
                            }
                        }}
                        onPause={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('pause');
                            }
                        }}
                        onEnded={() => {
                            if (onPlayStateChange) {
                                onPlayStateChange('pause');
                            }
                        }}
                    />
                )}

            </div>
        );
    }

    // Nothing exists

    return (
        <div
            style={style}
            className={className}
            css={{
                objectFit,
                objectPosition,
            }}
        />
    );
};

export const query = graphql`
    fragment DatoCmsInternalAsset on DatoCmsFileField {
        isImage
        video {
            streamingUrl
            thumbnailUrl
            muxPlaybackId
        }
        width
        height
        alt
        smartTags
        objectPosition
        gatsbyImageData
        url
        format
        path
    }
    fragment DatoCmsExternalAsset on DatoCmsVideoField {
        thumbnailUrl
        provider
        url
    }
`;

export default DatoAsset;
